<template>
  <v-container>
    <user-profile-edit :user="$root.user" @close="$emit('close')"/>
  </v-container>
</template>

<script>
import UserProfileEdit from '@/components/base/UserProfileEdit'

export default {
  name: 'ProfileView',
  components: { UserProfileEdit },
  props: {
    config: Object
  }
}
</script>
